import React from "react"
import './404Page.scss';

const NotFoundPage: React.FC = () => {
    return (
        <div className="not-found-page">
            <div><img src="../../404.svg" alt="404 not found" loading="lazy" /></div>
            <div><h3>Page not found</h3></div>
        </div>
    )
}
export default NotFoundPage
