import React from "react"
import NotFoundPage from "../components/404Page/404Page"
import Seo from "../components/SEO/Seo"

const seo = {
  meta_title: "En nombre de dios | Página no encontrada",
  meta_description: "",
  seo_url: "https://www.ennombrededios.com/404",
  seo_image: {
    asset: {
      url: "",
    },
  },
}

const Page = () => {
  return (
    <>
      <Seo content={seo} />
      <NotFoundPage />
    </>
  )
}

export default Page
